<template>
    <div style="background-color: #172A46;">
        <!--<div id="waveform_m" ref="waveform_m" style="position: absolute; left: 20px; top: 5px; z-index: 20;"/> -->
        <div v-if="properties!==null" style="padding-bottom:1rem;">
        <h4 class="facility-info__title" >{{$t('leak_recovery.info_page')}}</h4>


           <!-- <div class="mt-3" style="padding: 10px 30px;"   v-if="properties!==null" > -->
            <b-tabs class="popup_tab_page" v-if="properties!==null" align="center">
              <b-tab :title="$t('leakage_info.properties')" active>

                         <b-row>
                        <b-col>
                            <!--<button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                            <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button> -->


                        </b-col>
                    </b-row>

                    <b-row >
                        <b-col style="height: 280px">
                            <!--<FFTChart :url="fft_url" ref="fftchart1" :height="fftchart_height1"/>-->
                             <!--<FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height1"/> -->
                          <b-row style=" margin: 0;">
                              <audio controls preload="auto" :src="aurl" style = "transform:scale(0.7);"/>
                         <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                            <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                              <span class="inner-bg">Y Axis</span> -->
                              <div class="status leak" >
                                  <input type="radio" v-model="vchart" value="0" id="fixed__" checked>
                                  <label for="fixed__" class="facility__label-radio">{{$t('leakage_info.fixed')}}</label>
                                  <input type="radio" v-model="vchart" value="1" id="proportional__">
                                  <label for="proportional__" class="facility__label-radio">{{$t('leakage_info.proportional')}}</label>
                             </div>
                          </b-row>
                              <FFTChart1 :url="fft_url"  ref="fftchart" :height="fftchart_height1"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.title')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['w_title']"  disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.columns.title')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['ma_title']" ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.columns.contents')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['ma_contents']" ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.columns.worker')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['worker']" disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.f_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['facility_id']" disabled ></b-input>
                        </b-col>
                    </b-row>
                      <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.f_name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['layer_type']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.assumed')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['leak_amount_value']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.before_l_id')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['water_leak_id']" disabled  ></b-input>
                        </b-col>
                    </b-row>                    
                    <b-row>

                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.columns.image')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['image']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                  
                    <!--
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">File Name</label>
                        </b-col>
                        <b-col sm="8">
                            <b-textarea class="facility__input height--auto" rows="2" v-model="properties['image_file_id']"  disabled ></b-textarea>
                        </b-col>
                    </b-row>
                    -->
                   <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.remarks')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['remarks']"   ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.columns.date')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['register_datetime']" disabled   ></b-input>
                        </b-col>
                    </b-row>               
                    <b-row >
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_recovery.columns.recovery_date')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input  class="input_date btn-gray-date m-0" type="datetime-local" size="sm" v-model="properties['recovery_datetime']"  ></b-input>
                        </b-col> 
                  
                    </b-row>  
                 </b-tab>
                <b-tab :title="$t('leakage_info.photo')">
                     <b-card v-if="image_yn" 
                                no-body
                                img-top
                                class="photo-card" >
                            <img thumbnail fluid src="@/assets/noimage.png" alt="image" bottom />
                     </b-card>
                     <div  v-if="!image_yn" style="overflow:auto;height: 700px;">
                    <p v-for="(item,i) in img_list" :key="i">
                            <b-card
                                no-body
                                img-top
                                class="photo-card"
                            >
                                <b-img thumbnail fluid :src="item" alt="image" bottom ></b-img>
                                <!--<b-img thumbnail fluid src="http://localhost:3000/api/get_img/leakage/6/1" alt="Image" bottom></b-img>-->
                            </b-card>      
                    </p>
                    </div>     
                    <!--b-card
                        no-body
                        class="photo-card"
                        img-top
                    >
                       
                        <b-img thumbnail fluid :src="img1" alt="Image" bottom></b-img>
                    </b-card>
                    <b-card
                        no-body
                        class="photo-card"
                        img-top
                    >
                         <b-img thumbnail fluid :src="img2" alt="Image" bottom></b-img>
                      
                    </b-card>
                    <b-card
                        no-body
                        img-top
                        class="photo-card"
                    >
                         <b-img thumbnail fluid :src="img3" alt="Image" bottom></b-img>
                     
                    </b-card-->
                </b-tab>
            </b-tabs>
            <!--</div> -->
          <b-row class="facility-info-footer-row m-0 mb-3">
               <b-col sm="6">
                 <b-button @click="close();" block class="modal-footer__button modal-footer__button-cancel">{{$t('button.close')}}</b-button>
               </b-col>
               <b-col sm="6">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('button.apply')}}</b-button>
               </b-col>
      </b-row>
        </div>
    </div>
</template>

<script>
//import WaveSurfer from 'wavesurfer.js';
import FFTChart1 from '@/components/chart/FFTChart1';


export default {
    name: 'DlgLeakageInfo',
    props: {
    },
    data() {
        return {
            properties: null,
       //     waveform_height1: 10,
            fftchart_height1: 180,
            is_playing: false,
       //     wavesurfer: null,
            image_yn : true,
            fft_url: '',
            aurl : '' ,
             img1:'',
             img2:'',
             img3:'',
             vchart : '0',       
             img_list: [],
        }
    },
 
     watch: {
        vchart() {
               //     if(this.vchart==='0') {
                //        alert("radio button : " + this.vchart) ;
                 //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
               //     }
               var c_url = this.fft_url.split('#') ;
               this.fft_url = c_url[0]+'#'+this.vchart ;
        }
    },
    methods: {
        handleOk() {

        },

        close(){
             this.properties = null;
             window.close();
        },

        show(idx) {
             this.properties = {} ;
             this.img_list =[];
             this.$http.get(this.URL+'api/get_leak_reco_info?idx='+idx).then((res) => {
                if(res.data.length<=0) return

              //  let leak_result = res.data.list[0]['leak_result'] ;

             //   if(leak_result === null)
             //      this.properties['leak_result'] =  "RT_OTHER" ;
              //  this.properties['idx'] =  i ;

                this.properties = res.data.list[0];
                
               
                 if(this.properties.image_list === null || this.properties.image_list  === undefined){

                  this.img_list.push(require('@/assets/noimage1.png')) 
                  //this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
                  this.img_list.push(require('@/assets/noimage1.png'));
                  this.img_list.push( require('@/assets/noimage1.png'));
                  
                }else{
                  var img = this.properties.image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
                }
                /*
                this.img1 = this.URL+'api/'+this.properties['file_path1'];
                this.img2 = this.URL+'api/'+this.properties['file_path2'];
                this.img3 = this.URL+'api/'+this.properties['file_path3'];
                */
                this.properties_bak = Object.assign({}, this.properties)

             })


            this.$refs.modal.show();
            /*
            this.wavesurfer = WaveSurfer.create({
                container: '#waveform_m',
                waveColor: 'blue',
                progressColor: 'black',
                autoCenter: true,
        //      height: this.waveform_height1,
                plugins: [
                ]
            });
            this.wavesurfer.on('finish', () => {
                this.is_playing = false
            });
                this.wavesurfer.load(this.URL+'api/get_wav/'+idx);
                 var c_url = this.fft_url.split('?') ;
               
            */
                this.aurl = this.URL+'api/get_wav/'+idx ;
               // this.fft_url = this.URL+"api/get_fft/"+idx;
               this.fft_url = this.URL+"api/get_fft/"+idx+'#'+this.vchart;
             
        },
        apply() {
             if(sessionStorage === null || typeof sessionStorage === "undefined"){
                return
            //}else if(sessionStorage.level === 'C'){
             
            }else if(sessionStorage.level > 1 ){                
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
           // alert("recovery_time"+ this.properties['recovery_datetime']) 
            let params = {} ;
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
            if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                params[keys[i]] = this.properties[keys[i]]
            }
            if(Object.keys(params).length<=0) return
              this.$http.get(this.URL+'api/update_leak_recove_info?idx='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                console.log(res.data);
              })
           // window.opener.app.parent_funcion();
            window.close();
          //  this.$emit('leaktable_refresh') ;
           // this.$refs.modal.hide();
        }

    },
       mounted () {
             this.properties = {} ;
          
             //옆에 메뉴를 없어지게 한다.
             document.getElementById("sidebar").style.display='none';
             document.getElementById("Header").style.display='none';
             document.getElementById("menu2").style.display='none';
             document.getElementById("main").style.paddingLeft ='0px' ;
             document.getElementById("main").style.paddingTop ='0px' ;           
             document.getElementsByClassName("content-wrap").item(0).style.height ='100%' ;
             document.getElementById("page-wrapper").style.minWidth = "500px";
            if(typeof this.$store.setting === "undefined") {
                this.$http.get(this.URL+'api/get_setting?m_id='+sessionStorage.m_id).then((res) => {
                    this.$store.setting = res.data
                })
            }
             this.$http.get(this.URL+'api/get_leak_reco_info?idx='+this.$route.query.idx).then((res) => {
                if(res.data.length<=0) return            

              //  let leak_result = res.data.list[0]['leak_result'] ;         
                
             //   if(leak_result === null)
             //      this.properties['leak_result'] =  "RT_OTHER" ;
              //  this.properties['idx'] =  i ;
                 
             //  res.data.list[0].recovery_datetime =  date1.toISOString().slice(0,16)
                  var date_str = res.data.list[0].recovery_datetime === null ?  res.data.list[0].recovery_datetime   : res.data.list[0].recovery_datetime.replace(' ','T')
                  res.data.list[0].recovery_datetime =  date_str
                  //alert( res.data.list[0].recovery_datetime.replace(' ','T')) ;
             //   let date = new Date(res.data.list[0].register_datetime) ;
             //   res.data.list[0].register_datetime = date.toISOString().slice(0,10)
                this.properties = res.data.list[0];   
                
                if(this.properties.image_list != null && this.properties.image_list  !== undefined){
                  this.image_yn =false;  
                  var img = this.properties.image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
                }    
                this.properties_bak = Object.assign({}, this.properties)
                setTimeout(()=>{
                    this.fft_url = this.URL+"api/get_fft/"+this.properties.water_leak_id+'#'+this.vchart;
                    this.aurl = this.URL+'api/get_wav/'+this.properties.water_leak_id;
                }, 200)
                

             })      
    },
    components: {
        FFTChart1: FFTChart1
        //FFTChart1_c:FFTChart1_c
    }
}
</script>

<style lang="scss">
.facility__label-radio {
  font-size: 12px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}

#Header {   display: none; }
.page-wrapper .page-content {
     padding: 0;
   
}
.facility-info__title {
    background: #172A46;;
}

.mb-3 {
    margin: 1rem!important;
}
.nav-tabs {
     background: #172A46;
    //  border-bottom: 1px solid #dee2e6; 
}
.tab-content {
    max-height: 1000px;    
    overflow-y: hidden;    
    overflow-x: hidden; 
}
.status.recovery_pop {
    float:right;
}
</style>
